<div class="modal">
  <div class="modal__header">
    <h1 class="modal__title">Перейменування документу</h1>
    <div class="close" mat-dialog-close></div>
  </div>
  <form class="modal__form"
        [formGroup]="editDocumentForm"
  >
    <mat-form-field class="form__field">
      <mat-label>Назва</mat-label>
      <input matInput formControlName="name">
      @if (editDocumentForm.controls.name.touched) {
        <mat-error>
          @if (editDocumentForm.controls.name.errors?.required) {
            <span>
              Не може бути пустим
            </span>
          }
        </mat-error>
      }

    </mat-form-field>

    <mat-dialog-actions class="action-button">
      <button mat-stroked-button
              class="btn__active"
              [disabled]="editDocumentForm.invalid || !isFormChanged"
              (click)="updateDocument()"
      >Зберегти</button>
    </mat-dialog-actions>

  </form>
</div>
