import { Component, Inject } from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {CategoriesService} from "../../../services";
import {FormFilUploadComponent} from "../../form-file-upload/form-fil-upload.component";
import {DragAndDropFilesDirective} from "../../../direcitves/drag-and-drop-files.directive";

@Component({
  selector: 'app-add-category',
  standalone: true,
  templateUrl: './add-category.component.html',
  imports: [
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatDialogClose,
    FormFilUploadComponent,
    DragAndDropFilesDirective
  ],
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent {

  public loading = false;
  file: File | null = null;
  public addCategoryForm: FormGroup = this.generateForm();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bankId: number },
    public dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    private categoriesService: CategoriesService,
  ) { }

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      iconFile: [ null, Validators.required]
    });
  }

  addCategory(form: FormGroup): void {
    this.loading = true;
    const newCategory = new FormData();

    newCategory.append('name', form.value.name);
    newCategory.append('iconFile', form.value.iconFile);

    this.categoriesService.addCategory(this.data.bankId, newCategory).subscribe(
      (data) => {
        this.dialogRef.close(data);
        this.loading = false;
      },
      () => this.loading = false
    );
  }
}
