import { Component } from '@angular/core';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatInputModule} from "@angular/material/input";
import {MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import { AdminService } from '../../../services';
import { BankingGroupPost } from '../../../interfaces';

@Component({
  selector: 'app-add-banking-group',
  templateUrl: './add-banking-group.component.html',
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDialogClose,
  ],
  standalone: true,
  styleUrls: ['./add-banking-group.component.scss']
})
export class AddBankingGroupComponent  {

  public loading = false;
  public addBankingGroupForm: FormGroup = this.generateForm();

  constructor(
    public dialogRef: MatDialogRef<BankingGroupPost>,
    private formBuilder: FormBuilder,
    private admin: AdminService,
  ) { }


  private generateForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(30)]]
    });
  }

  addBankingGroup(form: FormGroup): void {
    this.loading = true;

    const newBankingGroup = {
      name: form.value.name,
      email: form.value.email,
      password: form.value.password
    };

    this.admin.addNewBankingGroup(newBankingGroup).subscribe(
      (data: BankingGroupPost) => {
        this.dialogRef.close(data);
        this.loading = false;
      },
      () => this.loading = false
    );
  }

}
