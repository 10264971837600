<app-page-container>
  <app-page-header>
    <div class="actions">
      <button class="btn btn__icon" [ngClass]="{'btn__secondary': !isEdited, 'btn__active': isEdited}" (click)="edit()">
        <span>{{ isEdited ? 'Готово' : 'Змінити' }}</span>
      </button>
    </div>
  </app-page-header>


  <app-page-content>
    <div class="categories">

      <div class="categories_list" cdkScrollable>
        <div class="categories-header">
          <h2>Категорії</h2>
          @if (isEdited) {
            <button class="btn btn__primary btn__icon" (click)="openAddCategory()">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 1V15" stroke="currentColor" stroke-width="1.5" stroke-linecap="square"
                      stroke-linejoin="round"/>
                <path d="M0.999878 8H14.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="square"
                      stroke-linejoin="round"/>
              </svg>
              <span class="add">Створити категорію</span>
            </button>
          }
        </div>

        <div class="list"
             cdkDropList
             [cdkDropListDisabled]="!isEdited"
             (cdkDropListDropped)="dropCategory($event)">
          @for (category of allCategories;track category) {
            <app-category
              [ngClass]="{'active': category.id === currentCategory?.id, 'edited': isEdited}"
              cdkDrag
              cdkDragBoundary=".categories-list"
              [isEdited]="isEdited"
              (click)=categoryClick(category)
              [category]="category">
              @if (isEdited && category.id === currentCategory?.id) {
              Натисніть повторно для редагування
              }
            </app-category>
          } @empty {
            <div class="empty">Покищо немає категорій</div>
          }
        </div>
      </div>

      <app-documents-list
        class="documents-list"
        [allDocuments]="categoryAllDocuments"
        (documentToParent)="documentClick($event)"
        (openAddDocument)="openAddDocument($event)">
      </app-documents-list>

    </div>
  </app-page-content>

</app-page-container>

