import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import {IDocument, IDocumentEvent} from '../../interfaces';
import { CategoriesService } from '../../services';


@Component({
  selector: 'app-documents-list',
  standalone: true,
  imports: [
    CdkDropList,
    CdkDrag
  ],
  templateUrl: './documents-list.component.html',
  styleUrl: './documents-list.component.scss'
})
export class DocumentsListComponent {

  constructor(
    private categoriesService: CategoriesService,
  ) {}

  @Input() allDocuments: IDocument[] = [];
  @Output() documentToParent = new EventEmitter<IDocumentEvent>();
  @Output() openAddDocument = new EventEmitter<boolean>();

  public get isEdited(): boolean {
    return this.categoriesService.edited.getValue();
  }

  public deleteDocument(document: IDocument): void {
    this.documentToParent.emit({document, type: 'delete'})
  }

  public editDocument(document: IDocument): void {
    this.documentToParent.emit({document, type: 'edit'})
  }

  public  emitAddDocument(): void {
    this.openAddDocument.emit(true);
  }

  drop(event: CdkDragDrop<IDocument>) {
    moveItemInArray(this.allDocuments, event.previousIndex, event.currentIndex);
  }

}
