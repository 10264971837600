import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotifireComponent } from '../components/modals/notifire/notifire.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class ModalService {

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,) {
  }

  public openDialog<T, R = any>(modal: any): MatDialogRef<any> {
    return this.dialog.open<T, any, R>(modal);
  }

  public showMsg(msg: string, type: 'success' | 'error') {
    const snackBarRef = this.snackBar.openFromComponent(NotifireComponent, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: type,
      data: {msg}
    });

    snackBarRef.afterDismissed().subscribe()
  }

  public closeAll(): void {
    this.dialog.closeAll();
  }

}
