<div class="modal">
  <h2 mat-dialog-title>{{ title }}</h2>

  <mat-dialog-content>
    <p>{{ message }}</p>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button color="primary"
            mat-stroked-button
            mat-dialog-close="true">Так</button>

    <button mat-stroked-button mat-dialog-close>Скасувати</button>
  </mat-dialog-actions>

</div>
