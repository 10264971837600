import { Component, DestroyRef, inject, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragAndDropFilesDirective } from '../../../direcitves/drag-and-drop-files.directive';
import { FormFilUploadComponent } from '../../form-file-upload/form-fil-upload.component';
import { DocumentsService } from '../../../services/documents.service';

@Component({
  selector: 'app-add-document',
  standalone: true,
  imports: [
    FormsModule,
    MatDialogClose,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    DragAndDropFilesDirective,
    FormFilUploadComponent
  ],
  templateUrl: './add-document.component.html',
  styleUrl: './add-document.component.scss'
})
export class AddDocumentComponent {

  private destroyRef = inject(DestroyRef);
  public loading = false;
  file: File | null = null;
  public addDocumentForm: FormGroup = this.generateForm();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bankId: number, categoryId: number },
    public dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
  ) { }

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      document: [ null, Validators.required]
    });
  }

  addDocument(form: FormGroup): void {
    this.loading = true;
    const newDocument = new FormData;

    newDocument.append('name', form.value.name);
    newDocument.append('documentFile', form.value.document);

    this.documentsService.addDocument(this.data.bankId, this.data.categoryId, newDocument).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(
      (data) => {
        this.dialogRef.close(data);
        this.loading = false;
      },
      () => this.loading = false
    );
  }

  onFileChange(event: Event) {
    const file = (event.target as HTMLInputElement).files;
    if (file) {
      this.file = file[0];
      this.addDocumentForm.patchValue({ document: file[0] });
    }
  }

}
