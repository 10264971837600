import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AdminService } from '../services';
import { IBankInfo } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class BankResolver implements Resolve<IBankInfo> {

  constructor(private adminService: AdminService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IBankInfo> {
    return this.adminService.getBankInfo((route.paramMap.get('id') ?? 0) as number);
  }
}
