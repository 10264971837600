import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogRef
} from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IDocument} from '../../../interfaces';
import { filter } from 'rxjs/operators';
import { FormFilUploadComponent } from '../../form-file-upload/form-fil-upload.component';
import { DragAndDropFilesDirective } from '../../../direcitves/drag-and-drop-files.directive';
import { DocumentsService } from '../../../services/documents.service';

@Component({
  selector: 'app-edit-category',
  standalone: true,
  imports: [
    FormsModule,
    MatDialogClose,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatDialogActions,
    MatButtonModule,
    FormFilUploadComponent,
    DragAndDropFilesDirective
  ],
  templateUrl: './edit-document.component.html',
  styleUrl: './edit-document.component.scss'
})
export class EditDocumentComponent implements OnInit{

  public editDocumentForm: FormGroup = this.generateForm();
  public loading = false;
  public documentData!: IDocument;
  private originalFormValue!: { name: string;};


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { document: IDocument, bankId: number },
    private destroyRef: DestroyRef,
    public dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
  ) { }

  ngOnInit(): void {
    this.documentData = this.data.document;
    this.initForm();
  }

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  get isFormChanged(): boolean {
    return this.originalFormValue.name !== this.editDocumentForm.value.name
  }

  private initForm() {
    const name = this.documentData.name
    this.editDocumentForm.patchValue({name: name});
    this.originalFormValue = {name}
  }

  public updateDocument(): void {
    this.documentsService.updateDocument(
      this.data.bankId,
      this.documentData?.categoryId,
      this.documentData?.id,
      this.editDocumentForm.value?.name
    ).pipe(
      filter(Boolean),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe({
        next: data => {
          this.dialogRef.close(data);
        },
        error: err => {
          this.dialogRef.close(err);
        }
      });
  }
}
