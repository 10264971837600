import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../../services';

@Component({
  selector: 'app-home-page',
  templateUrl: './login-page.component.html',
  standalone: true,
  imports: [
    MatInputModule,
    NgIf,
    ReactiveFormsModule,
    MatButtonModule
  ],
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  public loginForm: FormGroup = this.generateForm();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthService,
  ) {}

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  login(form: FormGroup): void {
    this.auth.login(form.value.email, form.value.password)
  }

}
