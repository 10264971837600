import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { forkJoin, NEVER, Observable, of } from 'rxjs';
import { BankingGroup, IBankInfo } from '../interfaces';
import { AdminService } from '../services';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BankingGroupsResolver implements Resolve<BankingGroup[]> {
  private bankingGroups!: BankingGroup[];
  private banks!: IBankInfo[];

  constructor(
    private router: Router,
    private adminService: AdminService
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BankingGroup[]> {
    return forkJoin([this.adminService.getAllBankingGroup(), this.adminService.getAllBanks()]).pipe(
      switchMap(([bankingGroups, banks]) => {
        if (bankingGroups.length > 1) {
          return of(bankingGroups);
        } else if (bankingGroups.length === 1) {
          const bankingGroupId = bankingGroups[0]?.id;
          this.router.navigate(['banking-groups', bankingGroupId, 'banks']);
          return NEVER; // return an observable that never emits
        } else {
          const bankId = banks[0]?.id;
          this.router.navigate(['banks', bankId, 'tables']);
          return NEVER; // return an observable that never emits
        }
      })
    );
  }
}
