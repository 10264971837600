import { Component, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

type Value = File | null;
type OnChangeFn = (file: Value) => void;
type OnTouchedFn = () => void;

@Component({
  selector: 'app-form-file-upload',
  templateUrl: './form-fil-upload.component.html',
  styleUrls: ['./form-fil-upload.component.scss'],
  standalone: true,
  imports: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FormFilUploadComponent,
      multi: true
    }
  ]
})
export class FormFilUploadComponent implements ControlValueAccessor {

  @HostBinding('id')
  private hostId = 'file-upload-' + Math.random().toString(36).substring(2);

  public inputId = 'file-upload-input-' + Math.random().toString(36).substring(2);

  @Input()
  public accept = '';

  @Input()
  public previewUrl: string | ArrayBuffer | null | SafeUrl = '';

  public file: File | null = null;

  onChange: OnChangeFn = () => {};
  onTouched: OnTouchedFn = () => {};

  constructor(
    private readonly host: ElementRef<HTMLInputElement>,
   // private readonly notifier: NotifierService,
    private readonly sanitizer: DomSanitizer,
  ) {}

  @HostListener('change', ['$event.target.files'])
  onChangeInput( event: FileList ): void {
    this.file = event && event.item(0);

    if (this.file) {
      const allowedTypes = this.accept.split(',').map(type => type.trim());
      const isTypeAllowed = allowedTypes.includes(this.file.type);
      const isTypeApplicationAllowed = allowedTypes.some((type) => {
        const application = type.split('/')[0];
        return this.file?.type.startsWith(application);
      });

      if (!isTypeAllowed && !isTypeApplicationAllowed) {
        // this.notifier.notify('error', 'File type is not allowed');
        return;
      }
    }

    this.onChange(this.file);

    if (this.file) {
      if (this.file.type.startsWith('image')) {
        this.updatePreviewFromBlob(this.file);
      }
    }
  }

  @HostListener('dropFiles', ['$event'])
  onDropFiles(event: FileList): void {
    this.onChangeInput(event);
  }

  writeValue(value: any): void {
    this.file = null;

    if (value instanceof File) {
      this.file = value;
      this.updatePreviewFromBlob(value);
      return;
    }

    if (typeof value === 'string') {
      this.previewUrl = value;
      return;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.host.nativeElement.disabled = isDisabled;
  }

  registerOnChange(fn: OnChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchedFn): void {
    this.onTouched = fn;
  }

  private updatePreviewFromBlob(file: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(reader.result);
      } else {
        this.previewUrl = reader.result;
      }
    };
  }

}
