import { Component, Input } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '../../../services';
import {ConfirmationDialogComponent} from '../../modals/confirm-dialog/confirmation-dialog.component';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-page-header',
  standalone: true,
  imports: [],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  @Input() title: string | undefined;

  constructor(
    private matDialog: MatDialog,
    public auth: AuthService,
  ) {}

  public confirmToLogout(): void {
    const title = 'Вийти';
    const message = 'Ви впевненні що хочете вийти';

    const confirmationModal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        title
      },
      panelClass: 'confirmation-modal'
    });

    confirmationModal.afterClosed().pipe(
      filter(Boolean),
    ).subscribe((): void => {
      this.logout();
    });
  }

  private logout(): void{
    this.auth.logout()
  }

}
