import { Component, Inject } from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { AdminService } from '../../../services';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-add-bank',
  standalone: true,
  templateUrl: './add-bank.component.html',
  imports: [
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatDialogClose
  ],
  styleUrls: ['./add-bank.component.scss']
})
export class AddBankComponent {

  public loading = false;
  public addBankForm: FormGroup = this.generateForm();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bankingGroupId: number },
    public dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    private admin: AdminService,
  ) { }

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(30)]],
      language: ['en-CA', [Validators.required]]
    });
  }

  addBank(form: FormGroup): void {
    this.loading = true;
    const newBankInfo = {
      bankingGroupId: this.data.bankingGroupId,
      name: form.value.name,
      email: form.value.email,
      password: form.value.password,
      language: form.value.language
    };

    this.admin.addBank(newBankInfo).subscribe(
      (data) => {
        this.dialogRef.close(data);
        this.loading = false;
      },
      () => this.loading = false
    );
  }
}
