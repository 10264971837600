<div class="modal">
  <div class="modal__header">
    <h1 class="modal__title">Додавання групи банків</h1>
    <div class="close" mat-dialog-close></div>
  </div>
  <form class="modal__form"
        [formGroup]="addBankingGroupForm"
        (submit)="addBankingGroup(addBankingGroupForm)"
  >
    <mat-form-field class="form__field">
      <mat-label>Назва</mat-label>
      <input matInput formControlName="name">
      @if (addBankingGroupForm.controls.name.touched) {
        <mat-error>
          @if (addBankingGroupForm.controls.name.errors?.required) {
            <span>
              Не може бути пустим
            </span>
          }

        </mat-error>
      }

    </mat-form-field>

    <mat-form-field class="form__field">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email">
      @if (addBankingGroupForm.controls.email.touched && addBankingGroupForm.controls.email.invalid) {
        <mat-error>
          @if (addBankingGroupForm.controls.email.errors?.required) {
            <span>
              Не може бути пустим
            </span>
          }
          @if (addBankingGroupForm.controls.email.errors?.email) {
            <span>
              Некоректний Email
            </span>
          }
        </mat-error>
      }
    </mat-form-field>

    <mat-form-field class="form__field">
      <mat-label>Пароль</mat-label>
      <input type="password" matInput formControlName="password">
      @if (addBankingGroupForm.controls.password.touched && addBankingGroupForm.controls.password.invalid) {
        <mat-error>
          @if (addBankingGroupForm.controls.password.errors?.required) {
            <span>
               Не може бути пустим
            </span>
          }
          @if (addBankingGroupForm.controls.password.errors?.minlength) {
            <span>
              Мінімальна довжина 4 символи
            </span>
          }
          @if (addBankingGroupForm.controls.password.errors?.maxlength) {
            <span>
              Максимальна довжина 30 символів
            </span>
          }
        </mat-error>
      }
    </mat-form-field>

    <div class="modal__submit-btn">
      <button class="btn btn__primary" type="submit" [disabled]="addBankingGroupForm.invalid">
        @if (loading) {
            <mat-spinner diameter="22" color="primary"></mat-spinner>
        } @else {
          Створити групу банків
        }
      </button>
    </div>
  </form>
</div>
