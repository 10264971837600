import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { BankingGroup, BankingGroupPost, IBankInfo, BankPost, UserUpdate } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public bankDataSubject: BehaviorSubject<any>;
  public bankData: Observable<any>;


  constructor(
    private http: HttpClient
  ) {
    this.bankDataSubject = (new BehaviorSubject(null) as BehaviorSubject<any>);
    this.bankData = this.bankDataSubject.asObservable();
  }

  public addBank(bank: BankPost): Observable<any> {
    return this.http.post('@api_host/banks', bank);
  }

  public getAllBanks(): Observable<IBankInfo[]> {
    return this.http.get<IBankInfo[]>(`@api_host/banks`);
  }

  public getBanksByBankingGroupId(bankingGroupId: number): Observable<IBankInfo[]> {
    const params = new HttpParams().set('bankingGroupId', bankingGroupId.toString());
    return this.http.get<IBankInfo[]>(`@api_host/banks/`, { params });
  }

  public getBankInfo(id: number): Observable<IBankInfo> {
    return this.http.get<IBankInfo>(`@api_host/banks/${id}`);
  }

  public updateBankInfo({id, name}: Partial<IBankInfo>): Observable<any> {
    return this.http.patch(`@api_host/banks/${id}`, {
     name
    });
  }

  public getUserInfo(): Observable<any> {
    const BankDataObservable = this.http.get<any>(`@api_host/users/me`)
      .pipe(share());

    BankDataObservable
      .subscribe((data: any) => {
        this.bankDataSubject.next(data);
      });
    return BankDataObservable;
  }

  public removeBank(id: number): Observable<{message: string}> {
    return this.http.delete<{message: string}>(`@api_host/banks/${id}`);
  }

  public getAllBankingGroup(): Observable<BankingGroup[]> {
    return this.http.get<BankingGroup[]>(`@api_host/banking-groups`);
  }

  public getBankingGroupById(bankingGroupId: number): Observable<BankingGroup> {
    return this.http.get<BankingGroup>(`@api_host/banking-groups/${bankingGroupId}`);
  }

  public addNewBankingGroup(newBankingGroup: BankingGroupPost): Observable<BankingGroupPost> {
    return this.http.post<BankingGroupPost>('@api_host/banking-groups', newBankingGroup);
  }

  public updateUser(id: number, user: UserUpdate): Observable<BankingGroup> {
    return this.http.patch<BankingGroup>(`@api_host/banking-groups/${id}`, user);
  }

  public deleteBankingGroup(id: number): Observable<{message: string}> {
    return this.http.delete<{message: string}>(`@api_host/banking-groups/${id}`);
  }

}
