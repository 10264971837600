import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgClass } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AddBankComponent } from '../../components/modals/add-bank/add-bank.component';
import { AdminService } from '../../services';
import { filter, pluck, switchMap, tap } from 'rxjs/operators';
import { BankingGroup, IBankInfo } from '../../interfaces';
import { ConfirmationDialogComponent } from '../../components/modals/confirm-dialog/confirmation-dialog.component';
import {ContainerComponent} from '../../components/page/container/container.component';
import {HeaderComponent} from '../../components/page/header/header.component';
import {ContentComponent} from '../../components/page/content/content.component';


@Component({
  selector: 'app-super-admin',
  templateUrl: './banks-page.component.html',
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    ContainerComponent,
    HeaderComponent,
    ContentComponent
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {
        close: (dialogResult: any) => {}
      }
    }
  ],
  styleUrls: ['./banks-page.component.scss']
})
export class BanksPageComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  public allBanks!: any;
  public bankingGroupId!: number;
  public currentBankingGroup!: BankingGroup;
  public isEdited = false;

  constructor(
    private activateRoute: ActivatedRoute,
    private matDialog: MatDialog,
    private adminService: AdminService,
    private dialogRef: MatDialogRef<any>,
  ) { }

  ngOnInit(): void {
    this.getCurrentBankingGroup();
  }

  private getCurrentBankingGroup(): void {
    this.activateRoute.parent?.data.pipe(
      pluck('bankingGroup'),
      tap(bankingGroup => {
        this.currentBankingGroup = bankingGroup;
        console.log(this.currentBankingGroup);
      }),
      switchMap(bankingGroup => this.adminService.getBanksByBankingGroupId(bankingGroup.id)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((banks) => {
      this.allBanks = banks.reverse()
      console.log(this.allBanks)
    });
  }

  public openAddBank(): void {
    const bankModal = this.matDialog.open(AddBankComponent, {
      data: {
        bankingGroupId: this.currentBankingGroup.id
      }
    });

    bankModal.afterClosed().pipe(
      filter(Boolean)
    ).subscribe((data) => {
      // this.notifier.notify('success', this.translate.instant('pages.restaurants.addRestaurantSuccessfully'));
      this.allBanks = [data, ...this.allBanks];
    });
  }

  private removeBank(bankId: number):void {
    this.adminService.removeBank(bankId).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.allBanks = this.allBanks.filter((oldBank: IBankInfo) =>  oldBank.id != bankId)
    })
  }

  public deleteBank(bankId: number) {
    const title: string = "Видалення банку";
    const message = "Ви впевнені що хочете видалити банк";
    const confirmationModal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        title
      },
      panelClass: 'confirmation-modal'
    });

    confirmationModal.afterClosed().pipe(
      filter(Boolean),
      tap(() => {
        this.removeBank(bankId);
      }),
    ).subscribe(() => {
      this.dialogRef.close(bankId);
    });
  }

}
