import { Component } from '@angular/core';
import {LoadingComponent} from '../../loading/loading.component';

@Component({
  selector: 'app-page-content',
  standalone: true,
  imports: [
    LoadingComponent,
  ],
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss'
})
export class ContentComponent {

}
