<div class="modal">
  <div class="modal__header">
    <h1 class="modal__title">Редагування категорії</h1>
    <div class="close" mat-dialog-close></div>
  </div>
  <form class="modal__form"
        [formGroup]="editCategoryForm"
  >
    <mat-form-field class="form__field">
      <mat-label>Назва</mat-label>
      <input matInput formControlName="name">
      @if (editCategoryForm.controls.name.touched) {
        <mat-error>
          @if (editCategoryForm.controls.name.errors?.required) {
            <span>
              Не може бути пустим
            </span>
          }
        </mat-error>
      }

    </mat-form-field>

    <app-form-file-upload
      class="form__field"
      appDragAndDropFiles
      formControlName="icon"
      accept="image/*"
    >Оберіть іконку для категорії, або перетягніть її сюди</app-form-file-upload>

    <mat-dialog-actions class="action-button">
      <button mat-stroked-button
              class="btn__warning"
              (click)="deleteCurrentCategory()"
      >Видалити</button>

      <button mat-stroked-button
              class="btn__active"
              [disabled]="editCategoryForm.invalid || !isFormChanged"
              (click)="editCategory()"
      >Зберегти</button>
    </mat-dialog-actions>


  </form>
</div>
