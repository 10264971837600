<div class="modal">
  <div class="modal__header">
    <h1 class="modal__title">Додавання банку</h1>
    <div class="close" mat-dialog-close></div>
  </div>
  <form class="modal__form"
        [formGroup]="addBankForm"
        (submit)="addBank(addBankForm)"
  >
    <mat-form-field class="form__field">
      <mat-label>Назва</mat-label>
      <input matInput formControlName="name">
      @if (addBankForm.controls.name.touched) {
        <mat-error>
          @if (addBankForm.controls.name.errors?.required) {
            <span>Не може бути пустим</span>
          }
        </mat-error>
      }
    </mat-form-field>

    <mat-form-field class="form__field">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email">
      @if (addBankForm.controls.email.touched && addBankForm.controls.email.invalid) {
        <mat-error>
          @if (addBankForm.controls.email.errors?.required) {
            <span>Не може бути пустим</span>
          }
          @else if (addBankForm.controls.email.errors?.email) {
            <span>Некоректний Email</span>
          }
        </mat-error>
      }
    </mat-form-field>

    <mat-form-field class="form__field">
      <mat-label>Пароль</mat-label>
      <input type="password" matInput formControlName="password">
      @if (addBankForm.controls.password.touched && addBankForm.controls.password.invalid) {
        <mat-error>
          @if (addBankForm.controls.password.errors?.required) {
            <span>Не може бути пустим</span>
          } @else if (addBankForm.controls.password.errors?.minlength) {
            <span>Мінімальна довжина 4 символи</span>
          } @else if (addBankForm.controls.password.errors?.maxlength) {
            <span>Максимальна довжина 30 символів</span>
          }
        </mat-error>
      }
    </mat-form-field>

    <div class="modal__submit-btn">
      <button class="btn btn__primary" type="submit" [disabled]="addBankForm.invalid">
        @if (loading) {
            <mat-spinner diameter="22" color="primary"></mat-spinner>
        } @else {
          <span>Створити</span>
        }
      </button>
    </div>
  </form>
</div>
