import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { BankingGroup } from '../../interfaces';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { filter, pluck, tap } from 'rxjs/operators';
import { ModalService } from '../../services';
import { AddBankingGroupComponent } from '../../components/modals/add-banking-group/add-banking-group.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgClass } from '@angular/common';
import { ContainerComponent } from '../../components/page/container/container.component';
import { HeaderComponent } from '../../components/page/header/header.component';

@Component({
  selector: 'app-banking-groups-page',
  templateUrl: './banking-groups-page.component.html',
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    ContainerComponent,
    HeaderComponent,
  ],
  styleUrls: ['./banking-groups-page.component.scss']
})
export class BankingGroupsPageComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  public allBankingGroups!: BankingGroup[];
  public isEdited = false;

  constructor(
    private router: Router,
    private dialog: ModalService,
    private activateRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activateRoute.data.pipe(
      pluck('bankingGroups'),
      tap(bankingGroups => {
        this.allBankingGroups = bankingGroups.reverse();
        console.log(this.allBankingGroups);
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
    this.openAddBankingGroup()
  }

  public openAddBankingGroup(): void {
    const addingBankingGroupModal = this.dialog.openDialog<AddBankingGroupComponent, BankingGroup>(AddBankingGroupComponent);

    addingBankingGroupModal.afterClosed().pipe(
      filter((data) => !!data?.id)
    ).subscribe((data: BankingGroup): void => {
      console.log("BG", data);
      this.allBankingGroups = [data, ...this.allBankingGroups];
    });
  }
}
