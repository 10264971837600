import { Component, DestroyRef, OnInit} from '@angular/core';
import { ConnectionStatusService } from '../../../services';
import { pluck } from 'rxjs/operators';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { IBankInfo } from '../../../interfaces';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HeaderComponent } from '../../page/header/header.component';


@Component({
  selector: 'app-bank-layout',
  templateUrl: './bank-layout.component.html',
  standalone: true,
  imports: [
    HeaderComponent,
    RouterOutlet,
  ],
  styleUrls: ['./bank-layout.component.scss']
})
export class BankLayoutComponent implements OnInit {

  public bank!: IBankInfo;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly connectionStatus: ConnectionStatusService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.pipe(
      pluck('bank'),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((bank: IBankInfo) => {
      this.bank = bank;
    });
  }

  get isOnline(): boolean {
    return this.connectionStatus.online$.getValue();
  }

}
