@if (!isOnline) {
  <div class="network-offline">
    <div innerHTML="offline"></div>
    @if (isOnline) {
      <div innerHTML="serviceConnection"></div>
    }
  </div>
}

<router-outlet></router-outlet>

