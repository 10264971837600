<input [id]="inputId" type="file" [accept]="accept"/>

@if (previewUrl || file) {
  <div class="file-preview">
    @if (previewUrl) {
      <img [src]="previewUrl" alt="Preview"/>
    } @else if (!previewUrl && file) {
      <div class="file-name">{{ file.name }}</div>
    }
  </div>
}
  <div class="file-select">
    <label [for]="inputId">Обрати файл</label>
  </div>

<ng-content></ng-content>
