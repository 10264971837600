import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { BankingGroup } from '../interfaces';
import { AdminService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class BankingGroupResolver implements Resolve<BankingGroup> {
  constructor(private adminService: AdminService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<BankingGroup> {
    return this.adminService.getBankingGroupById((route.paramMap.get('id') ?? 0) as number);
  }
}
