<div class="modal">
  <div class="modal__header">
    <h1 class="modal__title">Додавання категорії</h1>
    <div class="close" mat-dialog-close></div>
  </div>
  <form class="modal__form"
        [formGroup]="addCategoryForm"
        (submit)="addCategory(addCategoryForm)"
  >
    <mat-form-field class="form__field">
      <mat-label>Назва</mat-label>
      <input matInput formControlName="name">
      @if (addCategoryForm.controls.name.errors?.required) {
        <mat-error>
          Не може бути пустим
        </mat-error>
      }
    </mat-form-field>

    <app-form-file-upload
      class="form__field"
      appDragAndDropFiles
      formControlName="iconFile"
      accept="image/*"
    >Оберіть іконку для категорії, або перетягніть її сюди</app-form-file-upload>




    <div class="modal__submit-btn">
      <button class="btn btn__primary" type="submit" [disabled]="addCategoryForm.invalid">
        @if (loading) {
          <mat-spinner diameter="22" color="primary"></mat-spinner>
        } @else {
          Створити
        }
      </button>
    </div>
  </form>
</div>
