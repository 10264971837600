import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogRef
} from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CategoriesService } from '../../../services';
import { ICategory } from '../../../interfaces';
import { ConfirmationDialogComponent } from '../confirm-dialog/confirmation-dialog.component';
import { filter, tap } from 'rxjs/operators';
import { FormFilUploadComponent } from '../../form-file-upload/form-fil-upload.component';
import { DragAndDropFilesDirective } from '../../../direcitves/drag-and-drop-files.directive';

@Component({
  selector: 'app-edit-category',
  standalone: true,
  imports: [
    FormsModule,
    MatDialogClose,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatDialogActions,
    MatButtonModule,
    FormFilUploadComponent,
    DragAndDropFilesDirective
  ],
  templateUrl: './edit-category.component.html',
  styleUrl: './edit-category.component.scss'
})
export class EditCategoryComponent implements OnInit{

  public editCategoryForm: FormGroup = this.generateForm();
  public loading = false;
  public icon: File | null = null;
  public categoryData!: ICategory;
  private originalFormValue!: { name: string; icon: any };


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { category: ICategory, bankId: number },
    private destroyRef: DestroyRef,
    public dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    private categoriesService: CategoriesService,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.categoryData = this.data.category;
    this.initForm();

  }

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      icon: [ '' , Validators.required]
    });
  }

  get isFormChanged() {
    return this.originalFormValue.name !== this.editCategoryForm.value.name
      || this.originalFormValue.icon !== this.editCategoryForm.value.icon;
  }

  private initForm() {
    const { name, icon } = this.categoryData;

    this.editCategoryForm.patchValue({ name, icon });
    this.originalFormValue = { name, icon };
  }

  public editCategory(): void {
    const editCategory= new FormData();

    editCategory.append('name', this.editCategoryForm.value.name);
    if( this.originalFormValue.icon !== this.editCategoryForm.value.icon ){
      editCategory.append('iconFile', this.editCategoryForm.value.icon);
    }


    this.categoriesService.updateCategory(
      this.data.bankId,
      this.categoryData?.id,
      editCategory
    ).pipe(
      filter(Boolean),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe({
        next: data => {
          this.dialogRef.close(data);
        },
        error: err => {
          this.dialogRef.close(err);
        }
      });
  }

  removeCategory(bankId: number,  categoryId: number):void {
    this.categoriesService.deleteCategory(bankId, categoryId).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe()
  }

  public deleteCurrentCategory() {
    const title: string = "Видалення категорії";
    const message = "Ви впевнені що хочете видалити категорію";
    const confirmationModal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        title
      },
      panelClass: 'confirmation-modal'
    });

    confirmationModal.afterClosed().pipe(
      filter(Boolean),
      tap(() => {
        this.removeCategory(this.data.bankId, this.categoryData.id);
      }),
    ).subscribe({
        next: () => {
          this.dialogRef.close(this.categoryData.id);
        },
        error: (error) => {
          this.dialogRef.close(error);
        }
      });
  }
}
