<div class="login-wrap">

  <h1 class="login-wrap__title">Вхід</h1>
  <form
    class="login-wrap__form"
    novalidate
    [formGroup]="loginForm"
    (submit)="login(loginForm)"
  >
    <mat-form-field class="login-wrap__text">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email">
      <mat-error *ngIf="loginForm.controls.email && loginForm.controls.email.invalid">
        <span *ngIf="loginForm.controls.email.errors?.required">Email не може бути пустим</span>
        <span *ngIf="loginForm.controls.email.errors?.email">Email не коректний</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>Пароль</mat-label>
      <input type="password" matInput formControlName="password">
      <mat-error *ngIf="loginForm.controls.password.touched && loginForm.controls.password.invalid">
        <span *ngIf="loginForm.controls.password.errors?.required">Не може бути пустим</span>
      </mat-error>
    </mat-form-field>


    <button mat-button class="btn btn__primary btn__block" type="submit" [disabled]="loginForm.invalid">
      Підтвердити
    </button>
  </form>
</div>
