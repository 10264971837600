<div class="modal">
  <div class="modal__header">
    <h1 class="modal__title">Додавання документу</h1>
    <div class="close" mat-dialog-close></div>
  </div>
  <form
    class="modal__form"
    [formGroup]="addDocumentForm"
    (submit)="addDocument(addDocumentForm)"
  >
    <mat-form-field class="form__field">
      <mat-label>Назва</mat-label>
      <input matInput formControlName="name">
      @if (addDocumentForm.controls.name.errors) {
        <mat-error>
          Не може бути пустим
        </mat-error>
      }
    </mat-form-field>

    <div class="form__field document-file-upload">
      @if (file) {
        <p class="document-name">{{ file?.name }}</p>
      }
      <div class="document-input">
        <button class="btn btn-light">Натисніть щоб обрати документ, або перетягніть його сюди</button>
        <input
          id="document"
          name="document"
          (change)="onFileChange($event)"
          type="file"
          accept=".pdf"
        />
      </div>
    </div>

    <div class="modal__submit-btn">
      <button class="btn btn__primary" type="submit" [disabled]="addDocumentForm.invalid">
        @if (loading) {
          <mat-spinner diameter="22" color="primary"></mat-spinner>
        } @else {
          Створити
        }
      </button>
    </div>
  </form>
</div>
