import { Routes } from '@angular/router';
import {LoginPageComponent} from "./pages/login-page/login-page.component";
import {CategoriesPageComponent} from "./pages/categories-page/categories-page.component";
import {AuthGuard} from "./guards/auth.guard";
import {BankingGroupsPageComponent} from "./pages/banking-groups-page/banking-groups-page.component";
import {BankingGroupsResolver} from "./resolvers/banking-groups.resolver";
import {BankingGroupLayoutComponent} from "./components/layouts/banking-group-layout/banking-group-layout.component";
import {BankingGroupResolver} from "./resolvers/banking-group.resolver";
import {BanksPageComponent} from "./pages/banks-page/banks-page.component";
import {BankLayoutComponent} from "./components/layouts/bank-layout/bank-layout.component";
import {BankResolver} from "./resolvers/bank.resolver";



export const routes: Routes = [

  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'banking-groups',
    component: BankingGroupsPageComponent,
    resolve: {bankingGroups: BankingGroupsResolver},
    canActivate: [AuthGuard]
  },
  {
    path: 'banking-groups/:id',
    component: BankingGroupLayoutComponent,
    resolve: {bankingGroup: BankingGroupResolver},
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'banks',
        pathMatch: 'full'
      },
      {
        path: 'banks',
        component: BanksPageComponent,
        canActivate: [AuthGuard],
      },
    ]
  },
  {
    path: 'banks/:id',
    component: BankLayoutComponent,
    resolve: {bank: BankResolver},
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'categories',
        pathMatch: 'full'
      },
      {
        path: 'categories',
        component: CategoriesPageComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/banks/1/categories',
  },
];
