import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions, MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  templateUrl: './confirmation-dialog.component.html',
  imports: [
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatButtonModule,
    MatDialogClose
  ],
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  public message!: string;
  public title!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {title: string, message: string},
    public dialogRef: MatDialogRef<any>
  ) { }

  ngOnInit(): void {
    this.message = this.data.message;
    this.title = this.data.title;
  }
}
