<app-page-container>
  <app-page-header title="Групи">
    @if (isEdited) {
      <button class="btn btn__primary btn__icon" (click)="openAddBankingGroup()">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1V15" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
          <path d="M0.999878 8H14.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
        <span class="add">Додати</span>
      </button>
    }
    <button class="btn btn__icon" [ngClass]="{'btn__secondary': !isEdited, 'btn__active': isEdited}" (click)="isEdited = !isEdited">
      <span>{{isEdited ? 'Готово' : 'Змінити'}}</span>
    </button>
  </app-page-header >



  <div class="bankingGroups-list">
    @for (bankingGroup of allBankingGroups; track bankingGroup.id) {
      <div class="bankingGroups-list__item" [routerLink]="['/bankingGroups', bankingGroup.id, 'banks']">
        <div class="item-column">{{bankingGroup.name}}</div>
        <div class="item-column">{{bankingGroup.email}}</div>
      </div>
    } @empty {
      <h2>Покищо немає</h2>
    }

  </div>
</app-page-container>


