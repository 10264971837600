<app-page-container>
  <app-page-header title="Відділення">
    @if (isEdited) {
      <button class="btn btn__primary btn__icon" (click)="openAddBank()">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1V15" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
          <path d="M0.999878 8H14.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="square"
                stroke-linejoin="round"/>
        </svg>
        <span class="add">Додати</span>
      </button>
    }
    <button class="btn btn__icon" [ngClass]="{'btn__secondary': !isEdited, 'btn__active': isEdited}"
            (click)="isEdited = !isEdited">
      <span>{{ isEdited ? 'Готово' : 'Змінити' }}</span>
    </button>
  </app-page-header>

  <app-page-content>
    <div class="bank-list">
      @for (bank of allBanks;track bank.id) {
        <div class="bank-list__item">
          <div class="item-column" [routerLink]="['/banks', bank.id, 'categories']">
            {{ bank.name }}
          </div>

          <div class="bank__control">
            @if (isEdited) {
              <button class="btn btn__icon remove-bank" (click)="deleteBank(bank.id)">
                <svg width="24px" height="24px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0"
                    fill="#f44336"/>
                  <path
                    d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0"
                    fill="#fafafa"/>
                </svg>
              </button>
            }
          </div>
        </div>
      } @empty {
        <div class="empty">
          <span>Відділення відсутні</span>
        </div>
      }
    </div>
  </app-page-content>

</app-page-container>
