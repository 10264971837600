import { Component } from '@angular/core';

@Component({
  selector: 'app-page-container',
  standalone: true,
  imports: [],
  templateUrl: 'container.component.html',
  styleUrl: 'container.component.scss'
})
export class ContainerComponent {}
