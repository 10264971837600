import { Component, DestroyRef, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgClass} from '@angular/common';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DocumentsListComponent} from '../../components/documents-list/documents-list.component';
import {IBankInfo, ICategory, IDocument, IDocumentEvent} from '../../interfaces';
import { CategoriesService, ModalService } from '../../services';
import { filter, pluck, switchMap, tap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../components/modals/confirm-dialog/confirmation-dialog.component';
import { AddDocumentComponent } from '../../components/modals/add-document/add-document.component';
import { CategoryComponent } from '../../components/category/category.component';
import { AddCategoryComponent } from '../../components/modals/add-category/add-category.component';
import { EditCategoryComponent } from '../../components/modals/edit-category/edit-category.component';
import { ContainerComponent } from '../../components/page/container/container.component';
import { HeaderComponent } from '../../components/page/header/header.component';
import { ContentComponent } from '../../components/page/content/content.component';
import { DocumentsService } from '../../services/documents.service';
import { CdkScrollable } from '@angular/cdk/overlay';
import {EditDocumentComponent} from '../../components/modals/edit-document/edit-document.component';


@Component({
  selector: 'app-categories-page',
  standalone: true,
  imports: [
    HeaderComponent,
    DocumentsListComponent,
    NgClass,
    RouterLink,
    MatDialogModule,
    MatDialogClose,
    MatDialogActions,
    CategoryComponent,
    CdkDrag,
    CdkDropList,
    ContainerComponent,
    HeaderComponent,
    ContentComponent,
    CdkScrollable,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {
        close: (dialogResult: any) => {}
      }
    }
  ],
  templateUrl: './categories-page.component.html',
  styleUrl: './categories-page.component.scss'
})
export class CategoriesPageComponent implements OnInit {

  public allCategories: ICategory[] = [];
  public currentCategory!: ICategory;
  public currentBank!: IBankInfo;
  public categoryAllDocuments: IDocument[] = [];
  private categoriesSequence: number[] = [];
  private documentsSequence: number[] = [];
  private categoriesNewSequence!: number[];
  private documentsNewSequence!: number[];


  constructor(
    private  readonly destroyRef: DestroyRef,
    private activateRoute: ActivatedRoute,
    private categoriesService: CategoriesService,
    private documentsService: DocumentsService,
    private dialogRef: MatDialogRef<any>,
    private matDialog: MatDialog,
    private modal: ModalService
  ) {
  }

  ngOnInit(): void {
    this.getAllCategoriesInCurrentBank();
    this.subscribeToCategoryChanges();
  }

  public get isEdited(): boolean {
    return this.categoriesService.edited.getValue();
  }

  private subscribeToCategoryChanges(): void {
    this.categoriesService.currentCategory$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(category => {
        this.currentCategory = category;
        this.getCategoriesDocument();
      });
  }

  private getAllCategoriesInCurrentBank(): void {
    this.activateRoute.parent?.data.pipe(
      pluck('bank'),
      tap(banks => {
        this.currentBank = banks;
      }),
      switchMap(banks => this.categoriesService.getAllCategory(banks.id).pipe(
        switchMap((categories) => {
          this.allCategories = categories
          this.allCategories.map(category => this.categoriesSequence.push(category.id))
          return categories;
        })
      )),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  public edit(): void {
    this.categoriesService.edited.next(!this.isEdited);

    if (!this.isEdited) {
      this.updateCategoriesSequence(this.categoriesNewSequence)
      this.documentsNewSequence = this.categoryAllDocuments.map(document => document.id)
      this.updateDocumentsSequence(this.documentsNewSequence)
    }

  }

  private updateCategoriesSequence(idsSequence: number[]): void {
      if (idsSequence?.length && this.isSequenceChanged(idsSequence, this.categoriesSequence)) {

        const sequence = {idsSequence}
        this.categoriesService.updateCategoriesSequence(this.currentBank.id, sequence).pipe(
          takeUntilDestroyed(this.destroyRef)
        ).subscribe(  {
            next: _ => {
              this.categoriesSequence = idsSequence
              this.modal.showMsg('Послідовність категорій успвшно змінено','success')
            },
            error: err =>  this.modal.showMsg(err,'error')
        });
    }
  }

  private updateDocumentsSequence(idsSequence: number[]): void {
    if (idsSequence?.length && this.isSequenceChanged(idsSequence, this.documentsSequence)) {

      const sequence = {idsSequence}
      this.documentsService.updateDocumentsSequence(this.currentBank.id, this.currentCategory.id, sequence).pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe({
        next: _ => {
          this.documentsSequence = idsSequence
          this.modal.showMsg('Послідовність документів успвшно змінено','success')
        },
      });
    }
  }

  public openAddCategory(): void {
    const categoryModal = this.matDialog.open(AddCategoryComponent, {
      data: {
        bankId: this.currentBank.id
      }
    });

    categoryModal.afterClosed().pipe(
      filter(Boolean)
    ).subscribe({
        next: (data) => {
          this.modal.showMsg(`Створено категорію ${data.name}`, 'success')
          this.allCategories = [data, ...this.allCategories];
        },
      });
  }

  public openEditCategory(category: ICategory): void {
    const editedCategory = this.matDialog.open(EditCategoryComponent, {
      data: {
        category,
        bankId: this.currentBank.id
      }
    });
    editedCategory.afterClosed().pipe(
      filter(Boolean)
    ).subscribe(
      {
      next: (data: any) => {
      if (typeof data === 'number') {
        this.allCategories = this.allCategories
          .filter(category => category.id !== data);
        this.modal.showMsg(`Кетегорію ${category.name} видалено`, 'success');
      } else {
        this.allCategories = this.allCategories
          .map(category => {
            return (category.id === data.id ? data : category);
          });
        this.modal.showMsg(`Кетегорію ${category.name} відредаговано`,'success');
      }
      },
      });
  }

  public categoryClick(category: ICategory): void {
    if (this.isEdited && this.currentCategory.id === category.id) {
      this.openEditCategory(category)
    }

    this.categoriesService.currentCategory$.next(category);
  }

  public dropCategory(event: CdkDragDrop<ICategory>): void {
    moveItemInArray(this.allCategories, event.previousIndex, event.currentIndex);
    this.categoriesNewSequence = this.allCategories.map(category => category.id)
  }

  public getCategoriesDocument(): void {
    this.documentsService.getAllDocumentsInCategory(this.currentBank.id, this.currentCategory.id).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: documents => {
        this.categoryAllDocuments = documents
        this.documentsSequence = this.categoryAllDocuments.map(document => document.id)
      },
    });
  }

  public documentClick(event: IDocumentEvent): void {
    if(event.type === 'delete') {
      this.deleteDocument(event.document)
    } else {
      this.openEditDocument(event.document)
    }
  }

  private removeDocument(bankId: number, categoryId: number, documentId: number): void {
    this.documentsService.deleteDocument(bankId, categoryId, documentId).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe()
  }

  private openEditDocument(document: IDocument) {
    const editDocument = this.matDialog.open(EditDocumentComponent, {
      data: {
        document,
        bankId: this.currentBank.id
      }
    });
    editDocument.afterClosed().pipe(
      filter(Boolean)
    ).subscribe(
      {
        next: (data: any) => {
            this.categoryAllDocuments = this.categoryAllDocuments
              .map(document => {
                return (document.id === data.id ? data : document);
              });
            this.modal.showMsg(`Документ ${document.name} відредаговано`,'success');
          },
      });
  }

  private deleteDocument(document: IDocument): void {
    const title: string = "Видалення документу";
    const message = "Ви впевнені що хочете видалити документ";
    const confirmationModal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        title
      },
      panelClass: 'confirmation-modal'
    });

    confirmationModal.afterClosed().pipe(
      filter(Boolean),
      tap(() => {
        this.removeDocument(this.currentBank.id, this.currentCategory.id, document.id);
      }),
    ).subscribe( {
      next: _ => {
        this.dialogRef.close();
        this.categoryAllDocuments = this.categoryAllDocuments
          .filter(doc => doc.id !== document.id)
        this.documentsSequence = this.documentsSequence
          .filter(id => id !== document.id)
        this.modal.showMsg(`Документ ${document.name} успішно видалено`,'success')
      },
    });
  }

  public openAddDocument(open: boolean): void {
    const documentModal = this.matDialog.open(AddDocumentComponent, {
      data: {
        bankId: this.currentBank.id,
        categoryId: this.currentCategory.id
      }
    });

    documentModal.afterClosed().pipe(
      filter(Boolean)
    ).subscribe({
      next: (data) => {
        this.categoryAllDocuments = [data, ...this.categoryAllDocuments];
        this.documentsSequence = [data.id, ...this.documentsSequence]
        this.modal.showMsg(`Документ ${data.name} успішно додано`,'success')
      },
    });

  }

  private isSequenceChanged(currentSequence: number[], initialSequence: number[]): boolean {

    if (initialSequence.length !== currentSequence.length) {
      return true;
    }

    for (let i = 0; i < initialSequence.length; i++) {
      if (initialSequence[i] !== currentSequence[i]) {
        return true;
      }
    }

    return false;
  }
}
