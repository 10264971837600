import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appDragAndDropFiles]'
})
export class DragAndDropFilesDirective {

  @Output()
  dropFiles = new EventEmitter<FileList>();

  @HostBinding('class.dragover')
  dragover = false;

  @HostListener('dragover', ['$event'])
  public onDragOver(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragover = true;
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragover = false;
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragover = false;

    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.dropFiles.emit(files);
    }
  }

}
