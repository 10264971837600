import {DestroyRef, Injectable} from '@angular/core';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User, BankingGroup, IBankInfo } from '../interfaces';
import { AdminService } from './admin.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public user = new BehaviorSubject<User | null>(null);

  public bankingGroups = new BehaviorSubject<BankingGroup[]>([]);
  public banks = new BehaviorSubject<IBankInfo[]>([]);

  constructor(
    private readonly destroyRef: DestroyRef,
    private http: HttpClient,
    private router: Router,
    private adminService: AdminService,
  ) {}

  public login(email: string, password: string): Observable<any> {
    const subject$ = this.http.post('@api_host/users/login', {email, password});

    subject$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        this.saveToken(data.meta.token);

        this.user.next(data.user);

        this.adminService.getAllBanks().subscribe((banks) => {
          this.banks.next(banks);
      });

      this.adminService.getAllBankingGroup().subscribe((bankingGroups: BankingGroup[]) => {
        this.bankingGroups.next(bankingGroups);
      });

      this.router.navigate(['banks/1/categories']);
    });

    return subject$;
  }

  logout(): void {
        this.removeToken();
        this.router.navigate(['/login']);
        this.user.next(null);
        this.bankingGroups.next([]);
        this.banks.next([]);
  }

  public fetchCurrentUser(): Observable<User> {
    return this.http.get<User>('@api_host/users/me');
  }

  isLoggined(): boolean {
    return this.hasToken();
  }

  private saveToken(token: string): void {
    localStorage.setItem('token', token);
  }

  private removeToken(): void {
    localStorage.removeItem('token');
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  public hasToken(): boolean {
    return localStorage.getItem('token') !== null;
  }

  public area(): 'auth' | 'bankingGroups' | 'bankingGroup' | 'bank' {
    if (this.bankingGroups.getValue().length > 1) {
      return 'bankingGroups';
    }

    if (this.bankingGroups.getValue().length === 1) {
      return 'bankingGroup';
    }

    if (this.banks.getValue().length > 0) {
      return 'bank';
    }

    return 'auth';
  }

}
