import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services';
import { ModalService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class ErrorCatchingInterceptor implements HttpInterceptor {

  constructor(
    private modal: ModalService,
    private authService: AuthService
  ) {}

  get language(): string {
    return 'ukr';
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((response: HttpErrorResponse) => {
      const notification = (error: any) => {
        if (error.translation && error.translation[this.language]) {
          this.modal.showMsg(error.translation[this.language], 'error');
        } else if (error.message) {
          this.modal.showMsg(error.message, 'error');
        }
      };

      if (response) {
        switch (response.status) {
          case 401: {
            this.Error401Handler();
            break;
          }
        }
      }

      if (response.error?.violations && response.error?.violations?.length > 0) {
        response.error.violations.map((violation: any) => notification(violation));
      } else if (response.error.message) {
        notification(response.error);
      } else {
        console.log('error', 'Сервіс не доступний');
      }

      return throwError(response);
    }));
  }

  Error401Handler(): void {
    this.authService.logout();
    console.log('401');
  }

}
