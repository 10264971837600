import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {IDocument} from '../interfaces';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private readonly http: HttpClient
  ) { }

  public addDocument(banksId: number, categoryId: number, documents: FormData) {
    return this.http.post(`@api_host/banks/${banksId}/categories/${categoryId}/documents`, documents);
  }

  public getDocumentById (banksId: number, categoryId: number, documentId: number): Observable<IDocument> {
    return this.http.get<IDocument>(`@api_host/banks/${banksId}/categories/${categoryId}/documents/${documentId}`);
  }

  public getAllDocumentsInCategory(banksId: number, categoryId: number): Observable<IDocument[]> {
    return this.http.get<IDocument[]>(`@api_host/banks/${banksId}/categories/${categoryId}/documents`);
  }

  public getAllDocuments(): Observable<IDocument[]> {
    return this.http.get<IDocument[]>(`@api_host/banks/1/documents`);
  }


  public updateDocument (
    banksId: number,
    categoryId: number,
    documentId: number,
    name: string
  ) {
    return this.http.patch<IDocument>(`@api_host/banks/${banksId}/categories/${categoryId}/documents/${documentId}`, {name: name});
  }

  public updateDocumentsSequence(banksId: number, categoryId: number, sequence: any) {
    return this.http.post(`@api_host/banks/${banksId}/categories/${categoryId}/documents/sequence`, sequence);
  }

  public deleteDocument (banksId: number, categoryId: number, documentId: number) {
    return this.http.delete<{ message: string }>(`@api_host/banks/${banksId}/categories/${categoryId}/documents/${documentId}`);
  }




}
