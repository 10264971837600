import {Component, Inject } from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';


@Component({
  selector: 'app-notifire',
  standalone: true,
  imports: [],
  templateUrl: './notifire.component.html',
  styleUrl: './notifire.component.scss'
})
export class NotifireComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data:
      { msg: string }
  ) {}

}
