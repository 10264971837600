import { Component, Input } from '@angular/core';
import { ICategory } from '../../interfaces';

@Component({
  selector: 'app-category',
  standalone: true,
  imports: [],
  templateUrl: './category.component.html',
  styleUrl: './category.component.scss'
})
export class CategoryComponent {
  @Input() category!: ICategory;
  @Input() isEdited!: boolean;
}
