import {Component, DestroyRef, OnInit} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { pluck } from 'rxjs/operators';
import { BankingGroup } from '../../../interfaces';
import { ConnectionStatusService, AuthService } from '../../../services';
import { HeaderComponent } from '../../page/header/header.component';

@Component({
  selector: 'app-banking-group-layout',
  templateUrl: './banking-group-layout.component.html',
  standalone: true,
  imports: [
    HeaderComponent,
    RouterOutlet,
  ],
  styleUrls: ['./banking-group-layout.component.scss']
})

export class BankingGroupLayoutComponent implements OnInit {

  public bankingGroup!: BankingGroup;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly connectionStatus: ConnectionStatusService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.pipe(
      pluck('bankingGroup'),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((bankingGroup: BankingGroup) => {
      this.bankingGroup = bankingGroup;
    });
  }

  get isOnline(): boolean {
    return this.connectionStatus.online$.getValue();
  }
}
