import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICategory } from '../interfaces';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {filter, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  public currentCategory$ = new Subject<ICategory>();
  public edited = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly http: HttpClient
  ) { }

  public addCategory(banksId: number, category: FormData) {
    return this.http.post(`@api_host/banks/${banksId}/categories`, category);
  }

  public getAllCategory(banksId: number): Observable<ICategory[]> {
    return this.http.get<ICategory[]>(`@api_host/banks/${banksId}/categories`).pipe(
      filter(category => category.length > 0),
      tap(category => {
        this.currentCategory$.next(category[0])
      })
    );
  }

  public getCategoryById (banksId: number, categoryId: number): Observable<ICategory> {
    return this.http.get<ICategory>(`@api_host/banks/${banksId}/categories/${categoryId}`);
  }

  public updateCategory (
    banksId: number,
    categoryId: number,
    category: FormData
  ) {
    return this.http.patch<ICategory>(`@api_host/banks/${banksId}/categories/${categoryId}`, category);
  }

  public updateCategoriesSequence(banksId: number, sequence: any) {
    return this.http.post(`@api_host/banks/${banksId}/categories/sequence`, sequence);
  }

  public deleteCategory (banksId: number, categoryId: number) {
    return this.http.delete<{ message: string }>(`@api_host/banks/${banksId}/categories/${categoryId}`);
  }

}
